import type {loader} from '~/routes/api.reportify-token/route';

import type {SerializeFrom} from '@remix-run/node';

export async function getMerchantAnalyticsApiToken() {
  return getToken('merchantAnalyticsApiToken');
}

export async function getAuthorizedAnalyticsToken() {
  return getToken('authorizedAnalyticsToken');
}

async function getToken(
  path: 'merchantAnalyticsApiToken' | 'authorizedAnalyticsToken',
) {
  const response = (await fetch('/api/reportify-token').then((res) =>
    res.json(),
  )) as SerializeFrom<typeof loader>;
  const token = response.data?.shop[path];
  if (!token) {
    throw new Error('No merchant analytics API token found');
  }
  return token;
}
